<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-2">
        <div class="form-group mb-3">
          <label>Date</label>
          <date-picker
            v-model="filters.date"
            append-to-body
            lang="en"
            confirm
          ></date-picker>
        </div>
      </div>
      <div class="col-lg-12">
        <TopViewers :filters="filters"></TopViewers>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import TopViewers from "../TopViewers.vue";
import DatePicker from "vue2-datepicker";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    TopViewers,
  },
  data() {
    return {
      filters: {
        date: null,
        viewer_id: null,
      },
      title: "Top 20 Viewers",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Newsletters",
        },
        {
          text: "Top Viewers",
          active: true,
        },
      ],
      reload: 0,
      params: {},
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // this.init();
      },
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <JsonExcel
        class="btn d-flex justify-content-end"
        :data="viewers"
        name="newsletter_topViewers.csv"
        type="csv">
        <i class="mdi mdi-download"></i>
        Download
      </JsonExcel>
      <div class="table-responsive">
        <b-table
          :items="viewers"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(is_prime)="row">
            <i
              class="fas fa-check-circle"
              style="color: #a4a4a4"
              v-if="row.item.is_prime !== 1"
            ></i>
            <i class="fas fa-check-circle" style="color: #1cbb8c" v-else></i>
          </template>
          <template v-slot:cell(action)="row">
            <div @click="sendEmail(row.item.id)">
              <i
                v-b-tooltip.hover
                title="Send a feedback email"
                style="cursor: pointer"
                class="ri-mail-send-line font-size-20"
              ></i>
            </div>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.ri-mail-send-line:hover {
  color: #1cbb8c;
}
</style>
<script>
import Vue from "vue";
import appConfig from "@/app.config";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);
export default {
  props: ["filters"],
  components: {
    JsonExcel
  },
  data() {
    return {
      viewers: [],
      overallCount: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      filter: null,
      filterOn: [],
      sortBy: "total_views",
      sortDesc: true,
      fields: [
        { key: "doctor", sortable: true, label: "Doctor" },
        { key: "speciality", sortable: true, label: "Speciality" },
        { key: "member_type", sortable: true, label: "Member Type" },
        { key: "city", sortable: true, label: "City" },
        { key: "state", sortable: true, label: "State" },
        { key: "country", sortable: true, label: "Country" },
        { key: "is_prime", sortable: true, label: "Prime" },
        { key: "total_views", sortable: true, label: "Views" },
        { key: "total_newsletters_viewed", sortable: true, label: "Viewed" },
        { key: "action", sortable: true, label: "Action" },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getTopViewersList();
      },
    },
  },
  computed: {
    rows() {
      return this.viewers.length;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getTopViewersList() {
      try {
        let url = appConfig.api_base_url + "/newsletters/viewers";
        if (this.filters.date != null) {
          url =
            appConfig.api_base_url +
            "/newsletters/viewers?date=" +
            this.getFormatedDate(this.filters.date);
        }
        const response = await this.$http.get(url);
        this.viewers = response.data.viewers;
        this.totalRows = this.viewers.length;
      } catch (err) {
        console.log(err);
      }
    },
    async sendEmail(id) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/newsletters/sendEmail?member_id=" + id
        );
        this.message = response.data.message;
        if (this.message == "OK") {
          this.$toast.open({
            message: "Email has been sent successfully!",
            type: "success",
            duration: 5000,
            dismissible: true,
          });
        } else {
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
            duration: 5000,
            dismissible: true,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    getFormatedDate(date) {
      try {
        if (date != null) {
          return moment(date).format("yyyy-MM-DD");
        }
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  },
  mounted() {
    this.getTopViewersList();
  },
};
</script>
